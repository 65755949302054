<template>
    <div>
    <h3>Lista Remessas SCR3040</h3>

    <v-overlay
      v-if="loading"
      absolute
      color="#B0BEC5"
      class="text-center">
      <v-progress-circular indeterminate>
      </v-progress-circular>
    </v-overlay>

    <v-simple-table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Data de Criação</th>
          <th>Data Base</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in SCR.SCR_set" :key="item.id" @click="goToRemessaItem(item.id)">
          <td>{{ item.id }}</td>
          <td>{{ formattedDate(item.created) }}</td>
          <td>{{ formattedDtBase(item.dtBase) }}</td>
          <td class="red--text" v-if="item.status == 'ER'">{{ statusRemessa(item.status) }}</td>
          <td class="orange--text" v-if="item.status == 'PE'">{{ statusRemessa(item.status) }}</td>
          <td class="blue--text" v-if="item.status == 'EC'">{{ statusRemessa(item.status) }}</td>
          <td class="green--text" v-if="item.status == 'OK'">{{ statusRemessa(item.status) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import utils from '../mixins/utils'

export default {
  
  mixins: [utils],

  data: () => ({
    loading: false,
  }),
  
  created () {
    this.fetchData()
  },

  computed: {
    ...mapState(['SCR',]),
  },

  methods: {
    async fetchData (url) {
      this.loading = true
      await this.$store.dispatch('SCR/getSCR', url)
      this.loading = false
    },
    goToRemessaItem(id) {
      this.$router.push({ path: `/remessas/item/${id}`});
    }
  }
}
</script>