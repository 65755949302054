<template>
  <div>
    <AppNav />
    <v-main>
      <v-container fluid v-if="$store.getters['account/loggedin']">
       <ListSCR />
      </v-container>
      <v-container v-else>
        <h3>Por favor, faça login para usar o sistema.</h3>
      </v-container>
    </v-main>
  </div>
</template>

<script>
 
  import AppNav from '../components/AppNav'
  import ListSCR from '../components/ListSCR'
 
  export default {
    
    components: {
       AppNav, ListSCR
    },
  }
</script>
