import axios from 'axios'

const state = {
  username: localStorage.getItem('username') || null,
  auth_token: localStorage.getItem('token') || null,
}

const getters = {
  getAuthHeader: (state) => {
    if(state.auth_token != null) {
      return { Authorization: `Token ${state.auth_token}` }
    } else {
      return null
    }
  },
  loggedin: state => !!state.auth_token,
}

const mutations = {
  login (state, { username, login_response }) {
    state.username = username
    state.auth_token = login_response.token
  },

  logout (state) {
    state.username = null
    state.auth_token = null
  },
}

const actions = {
  async login ({ commit }, { username, password }) {
    let response = await axios.post(process.env.VUE_APP_API_URL + `token/`, {
      username: username,
      password: password,
    })

    commit('login',
      { username: username,
        login_response: response.data
      })

    localStorage.setItem('username', username)
    localStorage.setItem('token', response.data.token)
  },

  async logout ({ commit }) {
    commit('logout')
    localStorage.removeItem('username')
    localStorage.removeItem('token')
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
