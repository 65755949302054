export default {
  methods: {
    formattedDate: function(timestamp) {
      if(!timestamp) {
        return '-'
      }
      let d = new Date(timestamp)
      return d.toLocaleString('pt-BR')
    },
    formattedTime: function(timestamp) {
      if(!timestamp) {
        return '-'
      }
      let t = new Date(timestamp)
      return t.toLocaleTimeString('pt-BR')
    },
    formattedDtBase: function(dtBase) {
      let data = dtBase.split('-')
      return `${data[2]}/${data[1]}/${data[0]}`
    },
    formataCNPJ (cnpj) {
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    },
    formataCPF (cpf) {
      return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    },
    formataCPFCNPJ (cpfcnpj) {
      if (cpfcnpj.length === 11) {
        return this.formataCPF(cpfcnpj)
      } else if (cpfcnpj.length === 14) {
        return this.formataCNPJ(cpfcnpj)
      }
    },
    formataTelefone (t) {
      return t.replace(/[^0-9]/g, '')
        .replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
    },
    nomeGBD (gbd) {
      if (gbd == 'QD') {
        return 'QUOD'
      } else if (gbd == 'SP') {
        return 'SPC'
      } else if (gbd == 'SE') {
        return 'SERASA'
      } else if (gbd == 'BV') {
        return 'BVS'
      } else if (gbd == 'BC') {
        return 'Banco Central'
      }
    },
    statusRemessa (status) {
      if (status == 'PE') {
        return 'Remessa Não Enviada'
      } else if (status == 'ER') {
        return 'Erro'
      } else if (status == 'EC') {
        return 'Extração Completa'
      } else if (status == 'OK') {
        return 'Remessa Enviada'
      }
    },
    statusArquivoEntrada (status) {
      if (status == 'PE') {
        return 'Extração Não Concluída'
      } else if (status == 'OK') {
        return 'Extração Concluída'
      } else if (status == 'ER') {
        return 'Erro'
      }
    },
    statusTransmissaoRemessa (status, tipo) {
      if (status == 'AN') {
        if (tipo == 'texto') {
          return 'Arquivos não Gerados'
        } else {
          return 'orange'
        }
      } else if (status == 'AG') {
        if (tipo == 'texto') {
          return 'Arquivos Gerados'
        } else {
          return 'blue'
        }
      } else if (status == 'AC') {
        if (tipo == 'texto') {
          return 'Arquivo Concatenado Gerado'
        } else {
          return 'blue'
        }
      } else if (status == 'AE') {
        if (tipo == 'texto') {
          return 'Arquivos Encriptados'
        } else {
          return 'blue'
        }
      } else if (status == 'OK') {
        if (tipo == 'texto') {
          return 'Arquivos Enviados'
        } else {
          return 'green'
        }
      } else if (status == 'ER') {
        if (tipo == 'texto') {
          return 'Erro'
        } else {
          return 'red'
        }
      }
    },
    statusXMLEnvio (status) {
      if (status == 'XN') {
        return 'XML Não Gerado'
      } else if (status == 'XG') {
        return 'XML Gerado'
      } else if (status == 'PT') {
        return 'Pendente Envio'
      } else if (status == 'TE') {
        return 'Envio Concluído'
      } else if (status == 'ER') {
        return 'Erro'
      }
    },
    naoValidoEnvioAutomaticoGBD (gbd) {
      var dict_gbds = {
        'QD': false,
        'SP': false,
        'SE': false,
        'BV': false,
        'BC': false
      }

      return dict_gbds[gbd]
    },
    formataNumero (num, casasDecimais) {
      if (!num) num = 0
      return parseFloat(num).toFixed(casasDecimais).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
}
