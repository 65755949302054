import axios from 'axios'

const state = {
  transmissaoRemessaCadastro_set:[],
  transmissaoRemessaHistorico_set: [],
  transmissaoRemessaItem: {}
}

const getters = {
  getTransmissaoRemessaListItemById: (state) => (id) => {
    return state.transmissaoRemessa_set.find(op => op.id === id)
  }
}

const mutations = {
  clearTransmissaoRemessa (state) {
    state.transmissaoRemessaCadastro_set = []
    state.transmissaoRemessaHistorico_set = []
  },

  setTransmissaoRemessa (state, { transmissaoRemessa_set }) {
    state.transmissaoRemessa_set = transmissaoRemessa_set
  },

  setTransmissaoRemessaItem (state, { transmissaoRemessaItem }) {
    state.transmissaoRemessaItem = transmissaoRemessaItem
  },

  addTransmissaoRemessaCadastro (state, TransmissaoRemessa) {
    state.transmissaoRemessaCadastro_set.push(TransmissaoRemessa)
  },

  addTransmissaoRemessaHistorico (state, TransmissaoRemessa) {
    state.transmissaoRemessaHistorico_set.push(TransmissaoRemessa)
  },
}

const actions = {
  async getTransmissaoRemessa ({ commit, rootGetters }, arquivoEntrada_set) {
    await commit('clearTransmissaoRemessa')

    for (let j = 0; j < arquivoEntrada_set.length; j++) {
      for (let i = 0; i < arquivoEntrada_set[j].transmissaoderemessa_set.length; i++) {
        let response = await axios.get(arquivoEntrada_set[j].transmissaoderemessa_set[i],
          { headers: rootGetters['account/getAuthHeader'], }
        )

        if (arquivoEntrada_set[j].tipo == 'CD') {
          await commit('addTransmissaoRemessaCadastro', response.data)
        } else {
          await commit('addTransmissaoRemessaHistorico', response.data)
        }
      }
    }
  },

  async getTransmissaoRemessaById ({ commit, rootGetters }, id) {
    const url = `TransmissaoRemessa/${id}/`
    let response = await axios.get(process.env.VUE_APP_API_URL + url,
      { headers: rootGetters['account/getAuthHeader'], }
    )

    await commit('setTransmissaoRemessaItem', {
        'transmissaoRemessaItem': response.data
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
