<template>
    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            class="mr-2"
            outlined
            color="#0030ff"
            v-bind="attrs"
            v-on="on"
            :disabled="arquivoEntradaStatus == 'AN' || arquivoEntradaStatus == 'ER' || transmissaoStatus == 'OK'"
        >
            Gerar Xml
        </v-btn>
      </template>
      <v-card>
            <v-card-title class="text-h5">
                Tem Certeza?
            </v-card-title>

            <v-card-text class="mt-4" v-if="transmissaoStatus == 'XE'">
                Gerar novos XMLs apagará todos os XMLs já gerados dessa Transmissão.
            </v-card-text>

            <v-btn
                color="#0030ff"
                text
                @click="dialog = false"
                class="my-4 ml-4"
            >
                Não
            </v-btn>

            <v-btn
                color="#0030ff"
                text
                class="my-4"
                :loading="loading"
                @click="gerarXmlsTransmissao()"
            >
                Gerar XML
            </v-btn>
        </v-card>

        <v-snackbar
            v-model="sucessoAcao"
            color="success"
            dark
        >
            Ação Feita com Sucesso.
        </v-snackbar>
    </v-dialog>
</template>

<script>

    import axios from 'axios'
    
    export default {
        name: 'FormRespRegistro',

        props: {
            url: String,
            arquivoEntradaStatus: String,
            transmissaoStatus: String
        },

        data: () => ({
            erroAcao: false,
            sucessoAcao: false,
            erro: null,
            dialog: false,
            loading: false,
        }),

        methods: {
            gerarXmlsTransmissao() {
                this.loading = true
                axios.post(this.url + "concatenar_xmls_envio/", null, { headers: this.$store.getters['account/getAuthHeader'], }).then(() => {
                    this.dialog = true
                    this.sucessoAcao = true
                    this.loading = false

                    setTimeout(() => {this.$router.go()}, 2000);
                }).catch((error) => {
                    this.erro = error
                    this.dialog = true
                    this.erroAcao = true
                    this.loading = false

                    setTimeout(() => {this.$router.go()}, 2000);
                })
            },
        }
    }

</script>