import axios from 'axios'

const state = {
  SCR_set: [],
  SCRItem: {}
}

const getters = {
  getSCRListItemById: (state) => (id) => {
    return state.SCR_set.find(op => op.id === id)
  }
}

const mutations = {
  clearSCR (state) {
    state.SCR_set = []
  },

  clearSCRItem (state) {
    state.SCRItem = {}
  },

  setSCR (state, { SCR_set }) {
    state.SCR_set = SCR_set
  },

  setSCRItem (state, { SCRItem }) {
    state.SCRItem = SCRItem
  },

  addSCR (state, SCR) {
    state.SCR_set.push(SCR)
  },
}

const actions = {
  async getSCR ({ commit, rootGetters }, url) {
    if (!url) {
      url = 'remessa/'
    }
    let response = await axios.get(process.env.VUE_APP_API_URL + url,
      { headers: rootGetters['account/getAuthHeader'], })

    await commit('setSCR', {
      'SCR_set': response.data.results.filter(filterRemessa)
    })
  },

  async getSCRById ({ commit, rootGetters }, id) {
    await commit('clearSCRItem')

      const url = `SCR/${id}/`
      let response = await axios.get(process.env.VUE_APP_API_URL + url,
        { headers: rootGetters['account/getAuthHeader'], }
      )

      await commit('setSCRItem', {
        'SCRItem': response.data
      })
  },
}

function filterRemessa(obj) {
  if (obj.tipo === 'SC') {
    return true;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
