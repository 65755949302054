import axios from 'axios'

const state = {
  remessa_set: [],
  remessaItem: {}
}

const getters = {
  getRemessaListItemById: (state) => (id) => {
    return state.remessa_set.find(op => op.id === id)
  }
}

const mutations = {
  clearRemessa (state) {
    state.remessa_set = []
  },

  clearRemessaItem (state) {
    state.remessaItem = {}
  },

  setRemessa (state, { remessa_set }) {
    state.remessa_set = remessa_set
  },

  setRemessaItem (state, { remessaItem }) {
    state.remessaItem = remessaItem
  },

  addRemessa (state, remessa) {
    state.remessa_set.push(remessa)
  },
}

const actions = {
  async getRemessa ({ commit, rootGetters }, url) {
    if (!url) {
      url = 'remessa/'
    }
    let response = await axios.get(process.env.VUE_APP_API_URL + url,
      { headers: rootGetters['account/getAuthHeader'], })

    await commit('setRemessa', {
      'remessa_set': response.data.results.filter(filterRemessa)
    })
  },

  async getRemessaById ({ commit, rootGetters }, id) {
    await commit('clearRemessaItem')

      const url = `remessa/${id}/`
      let response = await axios.get(process.env.VUE_APP_API_URL + url,
        { headers: rootGetters['account/getAuthHeader'], }
      )

      await commit('setRemessaItem', {
        'remessaItem': response.data
      })
  },
}

function filterRemessa(obj) {
  if (obj.tipo === 'CD') {
    return true;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
