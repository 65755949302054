<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      clipped
      fixed
      app
    >
      <v-list>
        <template>
          <v-list-item to="/cadpos">
            <v-list-item-action>
              <v-icon>mdi-inbox-arrow-up-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Cad Pos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/scr">
            <v-list-item-action>
              <v-icon>mdi-inbox-arrow-up-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>SCR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      fixed
      clipped-left
      clipped-right
      :color="barColor"
      >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-icon large color="deep-purple darken-4">mdi-bank-transfer</v-icon> CENTRAL CAD <span v-if="!inProd">(HOMOLOGAÇÃO)</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <template v-if="$store.getters['account/loggedin']">
          <AppAccountMenu />
        </template>
        <template v-else>
          <AppLoginDialog></AppLoginDialog>
        </template>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppAccountMenu from './AppAccountMenu'
import AppLoginDialog from './AppLoginDialog'

  export default {
    components: {
      AppLoginDialog,
      AppAccountMenu
    },

    computed: {
      ...mapState(['account',]),
      inProd: function () {
        return process.env.VUE_APP_API_URL == 'https://centralcad.pxsear.ch/api/'
      },
      barColor: function () {
        if (this.inProd) {
          return null
        } else {
          return "purple lighten-4"
        }
      },
    },

    data() {
      return {
        drawer: null,
      }
    },

    methods: {
    },
  }
</script>
