import axios from 'axios'

const state = {
  arquivoEntrada_set: [],
  arquivoEntradaItem: {}
}

const getters = {
  getArquivoEntradaListItemById: (state) => (id) => {
    return state.arquivoEntrada_set.find(op => op.id === id)
  }
}

const mutations = {
  clearArquivoEntrada (state) {
    state.arquivoEntrada_set = []
  },

  setArquivoEntrada (state, { arquivoEntrada_set }) {
    state.arquivoEntrada_set = arquivoEntrada_set
  },

  setArquivoEntradaItem (state, { arquivoEntradaItem }) {
    state.arquivoEntradaItem = arquivoEntradaItem
  },

  addArquivoEntrada (state, ArquivoEntrada) {
    state.arquivoEntrada_set.push(ArquivoEntrada)
  },
}

const actions = {
  async getArquivoEntradas ({ commit, rootGetters }, arquivoEntradasUrls) {
    await commit('clearArquivoEntrada')

    for (let i = 0; i < arquivoEntradasUrls.length; i++) {
      let response = await axios.get(arquivoEntradasUrls[i],
        { headers: rootGetters['account/getAuthHeader'], }
      )

      await commit('addArquivoEntrada', response.data)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}