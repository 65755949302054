import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'

import remessa from './modules/remessa'
import SCR from './modules/scr'
import arquivoEntrada from './modules/arquivoEntrada'
import transmissaoRemessa from './modules/transmissaoRemessa'
import xmlEnvio from './modules/xmlEnvio'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account: account,
    remessa: remessa,
    SCR: SCR,
    arquivoEntrada: arquivoEntrada,
    transmissaoRemessa: transmissaoRemessa,
    xmlEnvio: xmlEnvio
  },
})