<template>
  <div>
    <AppNav />
    <v-main>
      <v-container fluid>
       <RemessaListItem :remessaid="remessaid" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
 
  import AppNav from '../components/AppNav'
  import RemessaListItem from '../components/RemessaListItem'
 
  export default {
    props: {
      remessaid: String,
    },
    components: {
       AppNav, RemessaListItem
    },
  }
</script>
