import Vue from 'vue'
import VueRouter from 'vue-router'
import ListCadPos from '../views/ListCadPosView.vue'
import RemessaItem from '../views/RemessaItemView.vue'
import ListSCR from '../views/ListSCRView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: ListCadPos
  },
  {
    path: '/cadpos',
    name: 'Cad Pos',
    component: ListCadPos
  },
  {
    path: '/scr',
    name: 'SCR',
    component: ListSCR
  },
  {
    path: '/remessas/item/:id',
    name: 'Item da Remessa',
    component: RemessaItem,
    props: true
  }
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
