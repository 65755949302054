<template>
  <v-main class="pa-0">
    <v-container v-if="$store.getters['account/loggedin']">
      <v-card outlined>
        <v-card-title class="text-h4">Remessa</v-card-title>

        <v-overlay
          v-if="loading"
          absolute
          color="#B0BEC5"
          class="text-center">
          <v-progress-circular indeterminate>
          </v-progress-circular>
        </v-overlay>

        <v-card
            color="#385F73"
            class="d-inline-flex pa-2 ml-4"
            dark
            outlined
          >
          Data Base {{ remessa.remessaItem.dtBase }}
        </v-card>

        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Id</v-list-item-title>
                <v-list-item-subtitle>{{ remessa.remessaItem.id }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Data de Criação</v-list-item-title>
                <v-list-item-subtitle>
                  {{ formattedDate(remessa.remessaItem.created) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Status</v-list-item-title>
                <v-list-item-subtitle>
                  <span class="red--text" v-if="remessa.remessaItem.status == 'ER'">{{ statusRemessa(remessa.remessaItem.status) }}</span>
                  <span class="orange--text" v-if="remessa.remessaItem.status == 'PE'">{{ statusRemessa(remessa.remessaItem.status) }}</span>
                  <span class="blue--text" v-if="remessa.remessaItem.status == 'EC'">{{ statusRemessa(remessa.remessaItem.status) }}</span>
                  <span class="green--text" v-if="remessa.remessaItem.status == 'OK'">{{ statusRemessa(remessa.remessaItem.status) }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card>
      
      <v-divider></v-divider>

      <ItensDaRemessa :arquivoEntradasUrls="remessa.remessaItem.arquivoentrada_set"></ItensDaRemessa>
    </v-container>
    <v-container v-else fluid grid-list-md>
      <h1>Por favor, faça login para usar o sistema.</h1>
    </v-container>
  </v-main >
</template>

<script>
import { mapState } from 'vuex'
import utils from "../mixins/utils"
import ItensDaRemessa from "./ItensDaRemessa.vue"


export default {
  components: { 
    ItensDaRemessa
  },
 
  mixins: [utils],

  props: {
    remessaid: String,
  },

  computed: {
    ...mapState(['remessa',]),
  },

  data: () => ({
    transmissaoDeRemessa: [],
  }),

  created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      this.loading = true
      await this.$store.dispatch('remessa/getRemessaById', this.$route.params.id)
      this.loading = false
    },
  }
}
</script>