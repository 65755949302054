<template>
    <v-container class="pa-0">
        <v-card outlined class="pa-4 my-4" style="min-height: 200px" v-if="loading == true && arquivoEntrada.arquivoEntrada_set.length == 0">
            <v-overlay
                absolute
                color="#B0BEC5"
                class="text-center">
                <v-progress-circular indeterminate>
                </v-progress-circular>
            </v-overlay>
        </v-card>
        
        <v-card outlined class="pa-4 my-4" v-for="item in arquivoEntrada.arquivoEntrada_set" :key="item.id" style="min-height: 200px">
            <v-card-title class="pa-0 text-h4" v-if="item.tipo == 'CD'">Cadastro</v-card-title>
            <v-card-title class="pa-0 text-h4" v-if="item.tipo == 'HT'">Histórico</v-card-title>
            <v-card-title class="pa-0 text-h4" v-if="item.tipo == 'SC'">SCR</v-card-title>

            <v-overlay
                v-if="loading"
                absolute
                color="#B0BEC5"
                class="text-center">
                <v-progress-circular indeterminate>
                </v-progress-circular>
            </v-overlay>

            <div v-if="!loading">
                <v-row no-gutters>
                    <v-col>
                        <div outlined class="py-4">
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>ID</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>Clientes Extraídos</v-list-item-title>
                                            <v-list-item-subtitle>{{ formataNumero(item.numero_clis, 0) }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>  
                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                    v-if="item.tipo == 'SC'"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>Operações</v-list-item-title>
                                            <v-list-item-subtitle>{{ formataNumero(item.total_opers, 0) }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                    v-if="item.tipo == 'SC'"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>Saldo Devedor</v-list-item-title>
                                            <v-list-item-subtitle>{{ formataNumero(item.saldo_devedor, 2) }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>                                                                                                
                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>Total de Clientes</v-list-item-title>
                                            <v-list-item-subtitle>{{ formataNumero(item.total_clis, 0) }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>Andamento</v-list-item-title>
                                            <v-list-item-subtitle>{{ formataNumero(item.numero_clis/item.total_clis * 100, 2)}}%</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>                                
                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>Data de Criação</v-list-item-title>
                                            <v-list-item-subtitle>
                                            {{ formattedDate(item.created) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>Status</v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span class="red--text" v-if="item.status == 'ER'">{{ statusArquivoEntrada(item.status) }}</span>
                                                <span class="orange--text" v-if="item.status == 'PE'">{{ statusArquivoEntrada(item.status) }}</span>
                                                <span class="green--text" v-if="item.status == 'OK'">{{ statusArquivoEntrada(item.status) }}</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                    v-if="item.tipo == 'SC'"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>Início da Extração</v-list-item-title>
                                            <v-list-item-subtitle>
                                            {{ formattedDate(item.ini_preench) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>                                
                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                    v-if="item.tipo == 'SC' && !item.fim_preench"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>Estimativa de Término</v-list-item-title>
                                            <v-list-item-subtitle>
                                            {{ formattedDate(item.est_preench) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                    v-if="item.tipo == 'SC' && item.fim_preench"
                                >
                                    <v-list-item class="pa-0">
                                        <v-list-item-content>
                                            <v-list-item-title>Fim da Extração</v-list-item-title>
                                            <v-list-item-subtitle>
                                            {{ formattedDate(item.fim_preench) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>                                                                
                            </v-row>
                        </div>
                    </v-col>
                </v-row >

                <v-alert class="my-3" v-if="item.erro" type="error">
                    {{item.erro}}
                </v-alert>
                <v-row class="ma-0 mt-4">
                    <v-btn
                        color="#0030ff"
                        outlined
                        @click="preencherArquivoEntrada(item.url)"
                        :loading="loadingPreenchimento"
                        :disabled="item.tipo != 'SC' || (item.tipo == 'SC' && item.ini_preench != null)"
                    >
                        Iniciar Extração
                    </v-btn>
                    <v-btn
                        color="#0030ff"
                        class="ml-2"
                        outlined
                        @click="verificarArquivo(item.tipo, item.url)"
                        :loading="loadingExtracao"
                        :disabled="true"
                    >
                        Visualizar Extração
                    </v-btn>
                </v-row>
                <v-row  no-gutters>
                    <v-col>
                        <div>
                            <v-card outlined class="mt-4 pa-4" v-for="transmissao in (item.tipo == 'CD' ? transmissaoRemessa.transmissaoRemessaCadastro_set : transmissaoRemessa.transmissaoRemessaHistorico_set)" :key="transmissao.id"
                            :disabled="item.tipo == 'SC' && item.status != 'OK'" >
                                <h4 class="text-h5 purple--text text--lighten-1 mb-4">{{nomeGBD(transmissao.gbd)}}</h4>

                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="auto"
                                        md="auto"
                                        class="py-0"
                                    >
                                        <v-list-item class="pa-0">
                                            <v-list-item-content>
                                                <v-list-item-title>Id</v-list-item-title>
                                                <v-list-item-subtitle>{{transmissao.id}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="auto"
                                        md="auto"
                                        class="py-0"
                                    >
                                        <v-list-item class="pa-0">
                                            <v-list-item-content>
                                                <v-list-item-title>Clientes em XML</v-list-item-title>
                                                <v-list-item-subtitle>{{transmissao.idx_atual}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col
                                    cols="12"
                                    sm="auto"
                                    md="auto"
                                    class="py-0"
                                    >
                                        <v-list-item class="pa-0">
                                            <v-list-item-content>
                                                <v-list-item-title>Andamento</v-list-item-title>
                                                <v-list-item-subtitle>{{ formataNumero(transmissao.idx_atual/item.total_clis * 100, 2)}}%</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>                                      
                                    <v-col
                                        cols="12"
                                        sm="auto"
                                        md="auto"
                                        class="py-0"
                                    >
                                        <v-list-item class="pa-0">
                                            <v-list-item-content>
                                                <v-list-item-title>Status</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <span  v-bind:style="{ color: statusTransmissaoRemessa(transmissao.status, 'color') }">{{statusTransmissaoRemessa(transmissao.status, 'texto')}}</span>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="auto"
                                        md="auto"
                                        class="py-0"
                                    >
                                        <v-list-item class="pa-0">
                                            <v-list-item-content>
                                                <v-list-item-title>Início da geração do XML</v-list-item-title>
                                                <v-list-item-subtitle>{{ formattedDate(transmissao.ini_preench) }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="auto"
                                        md="auto"
                                        class="py-0"
                                    >
                                        <v-list-item class="pa-0" v-if="!transmissao.fim_preench">
                                            <v-list-item-content>
                                                <v-list-item-title>Estimativa de Término</v-list-item-title>
                                                <v-list-item-subtitle>{{ formattedDate(transmissao.est_preench) }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="auto"
                                        md="auto"
                                        class="py-0"
                                    >
                                        <v-list-item class="pa-0" v-if="transmissao.fim_preench">
                                            <v-list-item-content>
                                                <v-list-item-title>Fim da geração do XML</v-list-item-title>
                                                <v-list-item-subtitle>{{ formattedDate(transmissao.fim_preench) }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="auto"
                                        md="auto"
                                        class="py-0"
                                    >
                                        <v-list-item class="pa-0">
                                            <v-list-item-content>
                                                <v-list-item-title>Início da Concatenação</v-list-item-title>
                                                <v-list-item-subtitle>{{ formattedDate(transmissao.ini_concat) }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="auto"
                                        md="auto"
                                        class="py-0"
                                    >
                                        <v-list-item class="pa-0" v-if="!transmissao.fim_concat">
                                            <v-list-item-content>
                                                <v-list-item-title>Estimativa de Término</v-list-item-title>
                                                <v-list-item-subtitle>{{ formattedDate(transmissao.est_concat) }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="auto"
                                        md="auto"
                                        class="py-0"
                                    >
                                        <v-list-item class="pa-0" v-if="transmissao.fim_concat">
                                            <v-list-item-content>
                                                <v-list-item-title>Fim da Concatenação</v-list-item-title>
                                                <v-list-item-subtitle>{{ formattedDate(transmissao.fim_concat) }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>                                                                                                   
                                    <v-col
                                        cols="12"
                                        sm="auto"
                                        md="auto"
                                        class="py-0"
                                        v-if="transmissao.gbd == 'BC' && transmissao.status == 'AC'"
                                    >
                                        <!-- <v-list-item class="pa-0">
                                            <v-list-item-content>
                                                <v-list-item-title>Agregs</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ listaXmlsEnvio.length }} <span v-if="transmissao.xml_concatenado">(novos: {{ novosAgregs(listaXmlsEnvio) }})</span>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item> -->
                                    </v-col>
                                </v-row>
                                
                                <v-alert class="my-3" v-if="transmissao.erro" type="error">
                                    {{transmissao.erro}}
                                </v-alert>

                                <v-row class="ma-0 mt-4">
                                    <ConfirmacaoAcao :url="transmissao.url" :arquivoEntradaStatus="item.status" :transmissaoStatus="transmissao.status"></ConfirmacaoAcao>
                                </v-row>

                                <v-row class="ma-0 mt-4">
                                     <v-btn
                                        outlined
                                        color="#0030ff"
                                        :disabled="(transmissao.status != 'AC' && transmissao.status != 'AE') || naoValidoEnvioAutomaticoGBD(transmissao.gbd)"
                                        :loading="loadingTransmissao"
                                        @click="encriptarArquivoTransmissaoRemessa(transmissao.url)"
                                    >
                                        Encriptar Arquivo
                                    </v-btn>
                                    <v-btn
                                        outlined
                                        color="#0030ff"
                                        class="ml-2"
                                        :disabled="transmissao.status != 'AE' || naoValidoEnvioAutomaticoGBD(transmissao.gbd)"
                                        :loading="loadingTransmissao"
                                        @click="enviarArquivoTransmissaoRemessa(transmissao.url)"
                                    >
                                        Enviar arquivo a {{nomeGBD(transmissao.gbd)}}
                                    </v-btn>
                                    <v-btn
                                        outlined
                                        color="#0030ff"
                                        class="ml-2"
                                        :disabled="transmissao.respostas !== null"
                                        @click="dialogRespostas = true"
                                    >
                                        Visualizar respostas
                                    </v-btn>
                                </v-row>

                                <!-- <v-card color="#f0f3f4" class="mt-4" outlined v-if="transmissao.status == 'AC' && transmissao.gbd == 'BC'">
                                    <v-card-title class="text-subtitle-1">Lista Clis Agregs</v-card-title>
                                    <v-card-text class="pt-0" v-for="agreg in listaAgregs" :key="agreg">
                                        CPF/CNPJ: {{agreg.cpfcnpj}}
                                        <div>
                                            - IPOCs: 
                                            <span v-for="op in agreg.Op" :key="op">
                                                {{op.IPOC}}&nbsp;&nbsp;
                                            </span>
                                        </div>
                                    </v-card-text>
                                </v-card> -->
                                
                                <!-- <v-card color="#f0f3f4" class="mt-4" outlined v-if="transmissao.status == 'AC' && transmissao.gbd == 'BC'">
                                    <v-card-title class="text-subtitle-1">Lista de Erros nos Clis</v-card-title>
                                    <v-card-text class="pt-0" v-for="erro in listaErrosClis" :key="erro">
                                        {{erro}}
                                    </v-card-text>
                                </v-card> -->
                                
                                <v-dialog
                                :retain-focus="false"
                                    v-model="dialogXml"
                                    max-width="800px"
                                >
                                    <v-card class="pa-4">
                                        <v-card-title class="pa-0 pb-4 text-h4">{{xmlEnvio.xmlEnvioItem.nome_do_arquivo}}</v-card-title>
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="auto"
                                                md="auto"
                                                class="py-0"
                                            >
                                                <v-list-item class="pa-0">
                                                    <v-list-item-content>
                                                        <v-list-item-title>Id</v-list-item-title>
                                                        <v-list-item-subtitle>{{xmlEnvio.xmlEnvioItem.id}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="auto"
                                                md="auto"
                                                class="py-0"
                                            >
                                                <v-list-item class="pa-0">
                                                    <v-list-item-content>
                                                        <v-list-item-title>Status</v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <span class="red--text" v-if="xmlEnvio.xmlEnvioItem.status == 'XN'">{{statusXMLEnvio(xmlEnvio.xmlEnvioItem.status)}}</span>
                                                            <span class="blue--text" v-if="xmlEnvio.xmlEnvioItem.status == 'XG'">{{statusXMLEnvio(xmlEnvio.xmlEnvioItem.status)}}</span>
                                                            <span class="orange--text" v-if="xmlEnvio.xmlEnvioItem.status == 'PT'">{{statusXMLEnvio(xmlEnvio.xmlEnvioItem.status)}}</span>
                                                            <span class="green--text" v-if="xmlEnvio.xmlEnvioItem.status == 'TE'">{{statusXMLEnvio(xmlEnvio.xmlEnvioItem.status)}}</span>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                sm="auto"
                                                md="auto"
                                                class="py-0"
                                            >
                                                <v-list-item v-if="xmlEnvio.xmlEnvioItem.arquivo_bastao" class="pa-0">
                                                    <v-list-item-content>
                                                        <v-list-item-title>Arquivo Bastão?</v-list-item-title>
                                                        <v-list-item-subtitle>Sim</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                sm="auto"
                                                md="auto"
                                                class="py-0"
                                            >
                                                <v-list-item class="pa-0">
                                                    <v-list-item-content>
                                                        <v-list-item-title>Sequencial Remessa</v-list-item-title>
                                                        <v-list-item-subtitle>{{xmlEnvio.xmlEnvioItem.numero_remessa}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>

                                            <div v-if="item.tipo == 'SC'">
                                                
                                            </div>

                                            <!-- <v-simple-table class="mt-2" dense>
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>CPF/CNPJ</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="(b, i) in xmlEnvio.xmlEnvioItem.dados_remessa" :key="i">
                                                        <td>{{ b.nome }}</td>
                                                        <td>{{ formataCPFCNPJ(b.cpfcnpj) }}</td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table> -->
                                        </v-row>

                                        <!-- <v-card class="mt-4" outlined v-if="item.tipo == 'SC'">
                                            <v-card-title class="text-subtitle-1">Lista de Agregs</v-card-title>
                                            <v-card-text v-if="xmlEnvio.xmlEnvioItem.lista_clis_agregs">
                                                {{xmlEnvio.xmlEnvioItem.lista_clis_agregs}}
                                            </v-card-text>
                                        </v-card> -->

                                        <v-alert class="my-3" v-if="xmlEnvio.xmlEnvioItem.erro" type="error">
                                            {{xmlEnvio.xmlEnvioItem.erro}}
                                        </v-alert>
                                        
                                        <v-btn
                                            color="#0030ff"
                                            text
                                            @click="dialogXml = false"
                                            class="mt-4"
                                        >
                                            Voltar
                                        </v-btn>

                                        <v-btn
                                            color="#0030ff"
                                            text
                                            :loading="loadingAcao"
                                            @click="baixarArquivo(
                                                xmlEnvio.xmlEnvioItem.xml,
                                                xmlEnvio.xmlEnvioItem.nome_do_arquivo
                                            )"
                                            class="mt-4"
                                        >
                                            Baixar
                                        </v-btn>

                                        <!-- <v-btn
                                            color="#0030ff"
                                            text
                                            :loading="loadingAcao"
                                            @click="gerarXml(xmlEnvio.xmlEnvioItem.url)"
                                            class="mt-4"
                                            v-if="xmlEnvio.xmlEnvioItem.status != 'TE'"
                                        >
                                            Gerar Xml
                                        </v-btn> -->

                                        <!-- <v-btn
                                            color="#0030ff"
                                            text
                                            @click="encriptarXml(xmlEnvio.xmlEnvioItem.url)"
                                            class="mt-4"
                                            v-if="xmlEnvio.xmlEnvioItem.status != 'TE'"
                                        >
                                            Encriptar Xml
                                        </v-btn> -->
                                    </v-card>
                                </v-dialog>

                                <v-snackbar
                                    v-model="sucessoAcao"
                                    color="success"
                                    dark
                                >
                                    Ação Feita com Sucesso.
                                </v-snackbar>

                                <v-dialog
                                    v-model="dialogResposta"
                                    max-width="500px"
                                    v-if="erroAcao"
                                >
                                    <!-- <v-alert
                                        type="success"
                                        class="ma-0"
                                        v-if="sucessoAcao"
                                    >
                                        Reposta Salva com Sucesso!!
                                    </v-alert> -->
                                    <v-alert
                                        class="ma-0"
                                        color="pink darken-1"
                                        align="center"
                                        dark
                                        v-if="erroAcao"
                                    >
                                        Erro:
                                        <p>{{ erro }}</p>
                                        <v-btn
                                            text
                                            @click="erroEnvioRegistro = false, erroAcao = false, dialogResposta = false"
                                        >
                                            Voltar
                                        </v-btn>
                                    </v-alert>
                                </v-dialog>
                                <v-dialog
                                    v-model="dialogRespostas"
                                    max-width="1400px"
                                    :retain-focus="false"
                                >
                                    <v-card style="overflow-x: hidden; overflow-y: hidden">
                                        <v-card-title class="px-4">Respostas</v-card-title>
                                        <v-simple-table class="mt-2 px-6" dense>
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Protocolo</th>
                                                    <th>Erro</th>
                                                    <th>Arquivo</th>
                                                </tr>
                                                </thead>
                                                <tbody >
                                                    <tr v-for="(r, i)  in transmissao.respostas" :key="i">
                                                        <td>{{ r.id }}</td>
                                                        <td>{{ r.protocolo || '-' }}</td>
                                                        <td :class="r.erro ? 'red--text' : 'green--text'">{{ r.erro ? 'Sim' : 'Não' }}</td>
                                                        <td>
                                                            <v-btn
                                                                v-if="r.arquivo"
                                                                outlined
                                                                class="my-2"
                                                                color="#304FFE"
                                                                @click="baixarArquivo(
                                                                    r.arquivo,
                                                                    'resposta' + extencaoFromUrl(r.arquivo)
                                                                )"
                                                            >
                                                                Download
                                                            </v-btn>
                                                            <span v-else>-</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                       
                                        <v-row class="pt-5 px-5">
                                            <v-btn
                                                color="#0030ff"
                                                text
                                                class="mb-4 mx-2 pl-2"
                                                @click="dialogRespostas = false"
                                            >
                                                Voltar
                                            </v-btn>
                                        </v-row>
                                    </v-card>
                                </v-dialog>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <v-dialog
                v-model="dialogCadastro"
                max-width="1000px"
                v-if="item.tipo == 'CD'"
                >
                <v-card>
                    <v-card-title>Arquivo Entrada Cadastro</v-card-title>

                    <v-btn
                        depressed
                        color="#0030ff"
                        dark
                        class="my-3 ml-4"
                        outlined
                        @click="baixarExtracao(item, dadosCadastro)"
                        v-if="dadosCadastro.length > 0"
                    >
                        Baixar Extração
                    </v-btn>

                    <v-btn
                        color="#0030ff"
                        text
                        class="mx-2"
                        @click="dialogCadastro = false"
                    >
                        Voltar
                    </v-btn>

                    <v-simple-table class="mt-2" dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th>Nome</th>
                                <th>CPF/CNPJ</th>
                                <th>Telefones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="cli in dadosCadastro" :key="cli.id">
                                <td>{{ cli.nome }}</td>
                                <td>{{ formataCPFCNPJ(cli.cpfcnpj) }}</td>
                                <td>
                                    ({{ cli.telefones[0].ddd }})
                                    {{ cli.telefones[0].telefone }}
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-btn
                        color="#0030ff"
                        text
                        class="mb-4 mx-2"
                        @click="dialogCadastro = false"
                    >
                        Voltar
                    </v-btn>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="dialogHistorico"
                max-width="1000px"
                v-if="item.tipo != 'CD'"
            >
                <v-card>
                    <v-card-title class="px-4">Arquivo Entrada</v-card-title>

                    <v-btn
                        depressed
                        color="#0030ff"
                        dark
                        class="my-3 ml-4"
                        outlined
                        @click="baixarExtracao(item, dadosHistorico)"
                        v-if="dadosHistorico.length > 0"
                    >
                        Baixar Extração
                    </v-btn>

                    <v-btn
                        color="#0030ff"
                        text
                        class="mx-2"
                        @click="dialogHistorico = false"
                    >
                        Voltar
                    </v-btn>

                    <v-simple-table class="mt-2" dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th v-if="item.tipo != 'SC'">Nome</th>
                                <th>CPF/CNPJ</th>
                                <th>Número de Operações</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="cli in dadosHistorico" :key="cli.id">
                                
                                <td v-if="item.tipo != 'SC'">{{ cli.nome }}</td>
                                <td>{{ formataCPFCNPJ(cli.cpfcnpj) }}</td>
                                <td>{{ cli.Op.length }}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-btn
                        color="#0030ff"
                        text
                        class="mb-4 mx-2"
                        @click="dialogHistorico = false"
                    >
                        Voltar
                    </v-btn>
                </v-card>
            </v-dialog>

            <v-divider></v-divider>
        </v-card>
    </v-container>
</template>

<script>
    import axios from 'axios'
    import { mapState } from 'vuex'
    import utils from "../mixins/utils"
    import ConfirmacaoAcao from '../components/ConfirmacaoAcao.vue'
    export default {
        props: {
            arquivoEntradasUrls: Array,
        },

        components: {
            ConfirmacaoAcao
        },

        mixins: [utils],

        data: () => ({
            dialogXml: false,
            dialogCadastro: false,
            dialogHistorico: false,
            dialogGerarXML: false,
            dialogEncriptacao: false,
            dialogResposta: false,
            dialogRespostas: false,
            loading: false,
            loadingAcao: false,
            sucessoAcao: false,
            erroAcao: false,
            erro: null,
            auxXml: {},
            dadosCadastro: [],
            dadosHistorico: [],
            listaXmlsEnvio: [],
            listaAgregs: [],
            listaErrosClis: [],
            loadingExtracao: false,
            loadingPreenchimento: false,
            loadingTransmissao: false
        }),

        created () {
            this.fetchDataItensRemessa()
        },

        computed: {
            ...mapState(['remessa', 'arquivoEntrada', 'transmissaoRemessa', 'xmlEnvio',]),
        },

        methods: {
            novosAgregs (lista_clis_agregs) {
                let numNovosAgregs = 0
                lista_clis_agregs.map(function(num) {
                    if (num.gerar_saida == true) {
                        numNovosAgregs += 1
                    }
                });
                return numNovosAgregs
            },
            /** 
             * @param {string} urlStr Url de um arquivo para ser extraída a extensão
             */
            extencaoFromUrl(urlStr) {
                const dotLastIndex = urlStr.lastIndexOf('.')
                if (dotLastIndex === -1) return ''

                return urlStr.slice(dotLastIndex)
            },
            async fetchDataItensRemessa () {
                this.loading = true
                if(!this.arquivoEntradasUrls) {
                    await this.$store.dispatch('remessa/getRemessaById', this.$route.params.id)
                }
                await this.$store.dispatch('arquivoEntrada/getArquivoEntradas', this.arquivoEntradasUrls)
                await this.$store.dispatch('transmissaoRemessa/getTransmissaoRemessa', this.arquivoEntrada.arquivoEntrada_set)

                this.loading = false
            },
            async verificarArquivo(tipo, url) {
                this.loadingExtracao = true

                if (tipo == 'CD') {
                    if (this.dadosCadastro.length == 0) {
                        this.dadosCadastro = await this.buscarDados(url)
                    }
                    this.dialogCadastro = true
                } else {
                    if (this.dadosHistorico.length == 0) {
                        this.dadosHistorico = await this.buscarDados(url)
                    }
                    this.dialogHistorico = true
                }
                
                this.loadingExtracao = false
            },
            async buscarDados(url) {
                let response = await axios.get(url + "buscar_dados/",
                    { headers: this.$store.getters['account/getAuthHeader'], }
                )

                return response.data.dados
            },
            baixarExtracao(arquivoEntrada,dados) {
                var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(dados));
                var downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute("href", dataStr);
                downloadAnchorNode.setAttribute("download", "extracao" + arquivoEntrada.tipo + ".json");
                document.body.appendChild(downloadAnchorNode);
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            },
            async visualizarXml(url) {
                await this.$store.dispatch('xmlEnvio/getxmlEnvioById', url)
                this.auxXml = url
                this.dialogXml = true
            },
            baixarArquivo(url, nomeArquivo) {
                this.loadingAcao = true
                axios.get(url, null, { headers: this.$store.getters['account/getAuthHeader'], }).then((r) => {
                    let blob = new Blob([r.data], { type: 'application/csv'})
                    let arquivoUrl = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = arquivoUrl
                    link.setAttribute('download', nomeArquivo)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()

                    this.loadingAcao = false
                }).catch((error) => {
                    this.erro = error
                    this.dialogResposta = true
                    this.erroAcao = true
                    this.loadingAcao = false
                })
            },
            encriptarArquivoTransmissaoRemessa(transmissaoUrl) {
                this.loadingTransmissao = true
                axios.post(transmissaoUrl + 'encriptar_arquivos/', null, { headers: this.$store.getters['account/getAuthHeader'], }).then((data) => {
                    this.loadingTransmissao = false

                    if (data.data.erro != null) {
                        this.erro = data.data.erro
                        this.dialogResposta = true
                        this.erroAcao = true
                    } else {
                        this.dialogResposta = true
                        this.sucessoAcao = true
                    }

                    setTimeout(() => {this.$router.go()}, 3000);
                })
            },
            enviarArquivoTransmissaoRemessa(transmissaoUrl) {
                this.loadingTransmissao = true
                axios.post(transmissaoUrl + 'transmitir_arquivos/', null, { headers: this.$store.getters['account/getAuthHeader'], }).then((data) => {
                    this.loadingTransmissao = false

                    if (data.data.erro != null) {
                        this.erro = data.data.erro
                        this.dialogResposta = true
                        this.erroAcao = true
                    } else {
                        this.dialogResposta = true
                        this.sucessoAcao = true
                    }

                    setTimeout(() => {this.$router.go()}, 3000);
                })
            },
            gerarXml(url) {
                this.loadingAcao = true
                axios.post(url + "gerar_xml/", null, { headers: this.$store.getters['account/getAuthHeader'], }).then(() => {
                    this.dialogResposta = true
                    this.sucessoAcao = true
                    this.loadingAcao = false

                    setTimeout(() => {this.$router.go()}, 2000);
                }).catch((error) => {
                    this.erro = error
                    this.dialogResposta = true
                    this.erroAcao = true
                    this.loadingAcao = false
                })
            },
            async buscar_xmls_envio(url) {
                let response = await axios.get(url + "buscar_xmls_envio/",
                    { headers: this.$store.getters['account/getAuthHeader'], }
                )

                this.listaXmlsEnvio = response.data.xmlenvio_set
            },

            async buscar_lista_agregs(url) {
                let response = await axios.get(url + "buscar_lista_agregs/",
                    { headers: this.$store.getters['account/getAuthHeader'], }
                )

                this.listaAgregs = response.data.lista_clis_agregs
            },
            async buscar_lista_erros_clis(url) {
                let response = await axios.get(url + "buscar_lista_erros_clis/",
                    { headers: this.$store.getters['account/getAuthHeader'], }
                )

                this.listaErrosClis = response.data.lista_erros_clis
            },
            encriptarXml(url) {
                console.log(url)
            },
            preencherArquivoEntrada(url) {
                this.loadingPreenchimento = true
                axios.post(url + "preencher/", null, { headers: this.$store.getters['account/getAuthHeader'], }).then(() => {
                    this.loadingPreenchimento = false
                })
            }
        }
    }
</script>