import axios from 'axios'

const state = {
  xmlEnvio_set: [],
  xmlEnvioItem: {}
}

const getters = {
  getxmlEnvioListItemById: (state) => (id) => {
    return state.xmlEnvio_set.find(op => op.id === id)
  }
}

const mutations = {
  clearxmlEnvio (state) {
    state.xmlEnvio_set = []
  },

  clearxmlEnvioItem (state) {
    state.xmlEnvioItem = {}
  },

  setxmlEnvio (state, { xmlEnvio_set }) {
    state.xmlEnvio_set = xmlEnvio_set
  },

  setxmlEnvioItem (state, { xmlEnvioItem }) {
    state.xmlEnvioItem = xmlEnvioItem
  },

  addxmlEnvio (state, xmlEnvio) {
    state.xmlEnvio_set.push(xmlEnvio)
  },
}

const actions = {
  async getxmlEnvios ({ commit, rootGetters }, xmlEnviosUrls) {
    await commit('clearxmlEnvio')

    for (let i = 0; i < xmlEnviosUrls.length; i++) {
      let response = await axios.get(xmlEnviosUrls[i],
        { headers: rootGetters['account/getAuthHeader'], }
      )

      await commit('addxmlEnvio', response.data)
    }
  },

  async getxmlEnvioById ({ commit, rootGetters }, url) {
    await commit('clearxmlEnvioItem')

    let response = await axios.get(url,
      { headers: rootGetters['account/getAuthHeader'], }
    )

    await commit('setxmlEnvioItem', { 
        'xmlEnvioItem': response.data
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}